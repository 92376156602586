import $ from "jquery";

export default function() {
    let items = 0;
    let start = $("#offerItems").data("start");

    if(start > 0) {
        items = start;
    }

    if($("#addOffer").length) {
        $(document).on("click", "#offerAddItem", function(e) {
            e.preventDefault();
            items++;

            let html = $("#offerAddItemTemplate").html();
            html = html.replace(/REPLACEID/g, items);
            html = '<div id="itm-'+ items + '">'+ html + '</div>';

            $("#offerItems").append(html);
        });

        $(document).on("click", '[data-action="deleteOfferItem"]', function(e) {
            e.preventDefault();

            let id = $(this).data("id");
            let $elem = $("#itm-"+ id);

            let deletedItems = $("#deletedItems");
            if(deletedItems.length) {
                let item = $elem.data("item");
                let val = deletedItems.val().length > 0 ? deletedItems.val().split(",") : [];
                val.push(item);

                deletedItems.val(val.join(","));
            }

            $elem.remove();
        });
    }
};