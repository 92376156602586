import $ from "jquery";

export default function() {
    let $elem = $("#statisticsChart");

    if($elem.length) {
        let ctx = document.getElementById('statisticsChart').getContext('2d');
        let myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: $elem.data("labels").split(","),
                datasets: [{
                    label: 'Einnahmen in €',
                    data: $elem.data("data").split(","),
                    cubicInterpolationMode: 'monotone',
                    backgroundColor: [
                        'rgba(55, 179, 74, 0.05)'
                    ],
                    borderColor: [
                        'rgba(55, 179, 74, 1)'
                    ],
                    borderWidth: 2
                },
                {
                    label: 'Ausgaben in €',
                    data: $elem.data("data2").split(","),
                    cubicInterpolationMode: 'monotone',
                    backgroundColor: [
                        'rgba(206, 53, 44, 0.05)'
                    ],
                    borderColor: [
                        'rgba(206, 53, 44, 1)'
                    ],
                    borderWidth: 2
                },
                {
                    label: 'Gewinn in €',
                    data: $elem.data("data3").split(","),
                    cubicInterpolationMode: 'monotone',
                    backgroundColor: [
                        'rgba(240, 163, 10, 0.05)'
                    ],
                    borderColor: [
                        'rgba(240, 163, 10, 1)'
                    ],
                    borderWidth: 2
                }]
            },
            options: {
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        beginAtZero: true,
                    }],
                    yAxes: [{
                        display: true
                    }]
                }
            }
        });
    }
};