import $ from "jquery";
import QrScanner from 'qr-scanner';
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default function() {
    $(document).on("click", '[data-action="checkLocationID"]', function(e) {
        e.preventDefault();

        $.ajax({
            url: "/warehouse/locations/check",
            type: "POST",
            data: {
                "q": $("#warehouse_location_shortName").val()
            },
            success: function(data) {
                if(data.error === false) {
                    if(data.existing === false) {
                        $.alert({
                            title: "Prüfung erfolgreich",
                            content: "Es wurden keine Kollisionen gefunden."
                        });
                    } else {
                        $.alert({
                            title: "Prüfung fehlgeschlagen",
                            type: "red",
                            content: "Dieser Lagername existiert bereits."
                        });
                    }
                } else {
                    $.alert({
                        title: "Prüfung fehlgeschlagen",
                        type: "red",
                        content: data.message
                    });
                }
            }
        });
    });

    $(document).on("click", '[data-action="deleteWarehouseLocation"]', function(e) {
        e.preventDefault();

        $.confirm({
            title: "Bestätigung erforderlich",
            type: "red",
            content: "Wollen Sie dieses Lager wirklich löschen?",
        });
    });

    $(document).on("click", '[data-action="checkRackID"]', function(e) {
        e.preventDefault();

        let locationID = $(this).data("location");

        $.ajax({
            url: "/warehouse/rack/check/"+ locationID,
            type: "POST",
            data: {
                "q": $("#warehouse_rack_shortName").val()
            },
            success: function(data) {
                if(data.error === false) {
                    if(data.existing === false) {
                        $.alert({
                            title: "Prüfung erfolgreich",
                            content: "Es wurden keine Kollisionen gefunden."
                        });
                    } else {
                        $.alert({
                            title: "Prüfung fehlgeschlagen",
                            type: "red",
                            content: "Dieser Regalname existiert bereits."
                        });
                    }
                } else {
                    $.alert({
                        title: "Prüfung fehlgeschlagen",
                        type: "red",
                        content: data.message
                    });
                }
            }
        });
    });

    $(document).on("change", "#warehouse_item_location", function() {
        let id = $(this).val();

        if(id === "") {
            id = "all";
        }

        $.ajax({
            url: "/warehouse/items/get-racks/"+ id,
            type: "POST",
            success: function(data) {
                let $elem = $("#warehouse_item_rack");
                $elem.select2("destroy");
                $elem.html("");

                for(let i in data.data) {
                    if(data.data.hasOwnProperty(i)) {
                        let html = '<optgroup label="'+ i +'">';

                        for(let n in data.data[i]) {
                            if(data.data[i].hasOwnProperty(n)) {
                                html += '<option value="'+ n +'">'+ data.data[i][n] + '</option>'
                            }
                        }

                        html += '</optgroup>';
                        $elem.append(html);
                    }
                }

                $elem.select2();
            }
        });
    });

    $('#warehouse_search_name').autocomplete({
        serviceUrl: '/warehouse/query/name',
        type: "POST",
        params: {
            status: $("#warehouse_search_name").data("status")
        },
        minChars: 3,
        showNoSuggestionNotice: true,
        noSuggestionNotice: '<div class="autocomplete-suggestion"><i>Es wurden keine Treffer gefunden.</i></div>'
    });

    $('#warehouse_search_code').autocomplete({
        serviceUrl: '/warehouse/query/code',
        type: "POST",
        minChars: 1,
        width: 'flex',
        params: {
            status: $("#warehouse_search_code").data("status")
        },
        onSelect: function(suggestion) {
            $(this).val(suggestion.data);
        },
        showNoSuggestionNotice: true,
        noSuggestionNotice: '<div class="autocomplete-suggestion"><i>Es wurden keine Treffer gefunden.</i></div>'
    });

    $(document).on("click", '[data-action="deleteWarehouseItem"]', function(e) {
        e.preventDefault();

        let $elem = $(this);
        let id = $elem.data("id");
        let code = $elem.data("code");

        if(id > 0) {
            $.confirm({
                title: "Bestätigung erforderlich",
                type: "orange",
                content: "Wollen Sie den Artikel <code>"+ code + "</code> wirklich löschen?",
                buttons: {
                    cancel: {
                        btnClass: "button primary",
                        text: "Nein"
                    },
                    yes: {
                        btnClass: "button alert",
                        text: "Ja",
                        action: function() {
                            $.ajax({
                                url: "/warehouse/items/delete/"+ id,
                                type: "POST",
                                data: {code: code},
                                success: function(response) {
                                    if(response.error !== false) {
                                        $.alert({
                                            title: "Fehler",
                                            type: "red",
                                            content: response.error
                                        });
                                    } else {
                                        $.alert({
                                            title: "Artikel gelöscht",
                                            content: "Der Artikel wurde erfolgreich gelöscht.",
                                            buttons: {
                                                ok: {
                                                    btnClass: "button primary",
                                                    text: "Ok",
                                                    action: function() {
                                                        window.location.reload();
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                }
            });
        }
    });

    $(document).on("click", '[data-action="showWarehouseItemDetails"]', function (e) {
        e.preventDefault();

        let id = $(this).data("id");

        $.ajax({
            url: "/warehouse/items/get/"+ id,
            success: function(data) {
                showWarehouseItemDetails(data);
            }
        });
    });

    $(document).ready(function() {
        let $elem = $('[data-action="warehouseItemPreopen"]');
        if($elem.length) {
            let id = $elem.data("id");

            $.ajax({
                url: "/warehouse/items/get/" + id,
                success: function(data) {
                    showWarehouseItemDetails(data);
                }
            });
        }
    });

    $(document).on("click", '[data-action="warehouseScanQR"]', function(e) {
        e.preventDefault();

        scanQRCode(function(result) {
            $.ajax({
                url: "/warehouse/items/get/code/"+ result,
                success: function(data) {
                    if(data.error === false) {
                        showWarehouseItemDetails(data);
                    } else {
                        $.alert({
                            title: "Fehler",
                            type: "red",
                            content: data.error
                        });
                    }
                }
            });
        });
    });

    function showWarehouseItemDetails(data) {
        $.alert({
            title: data.title,
            content: data.content,
            columnClass: 'cell-xl-6 offset-xl-3 cell-lg-8 offset-lg-2',
            buttons: {
                edit: {
                    text: '<span class="icon mif-pencil"></span> <span class="d-none d-inline-md">Bearbeiten</span>',
                    btnClass: 'button primary float-left',
                    action: function() {
                        window.location.href = data.editURL;
                    }
                },
                print: {
                    text: '<span class="icon mif-print"></span>',
                    btnClass: 'button info float-left',
                    action: function() {
                        window.open(data.printURL, "_blank");
                    }
                },
                back: {
                    text: '<span class="icon mif-undo"></span>',
                    btnClass: 'button primary float-left ' + (data.instock === true ? 'd-none' : ''),
                    action: function() {
                        window.location.href = data.backURL;
                    }
                },
                lent: {
                    text: '<span class="icon mif-truck"></span>',
                    btnClass: 'button warning float-left ' + (data.lent === true ? 'd-none' : ''),
                    action: function() {
                        //window.location.href = data.lentURL;
                        let html = document.createElement("div");

                        let textarea = document.createElement("textarea");
                        textarea.setAttribute("data-role", "textarea");
                        textarea.setAttribute("id", "lendingNote"+ data.id);

                        let label = document.createElement("label");
                        label.setAttribute("class", "mb-2 d-block");
                        label.setAttribute("for", "lendingNote"+ data.id);
                        label.innerHTML = "Geben Sie eine Notiz ein: (optional)";

                        html.appendChild(label);
                        html.appendChild(textarea);

                        $.alert({
                            title: "Neue Ausleihe",
                            content: html,
                            buttons: {
                                cancel: {
                                    text: "Abbrechen",
                                    btnClass: "button secondary float-left"
                                },
                                save: {
                                    text: "Speichern",
                                    btnClass: "button primary",
                                    action: function() {
                                        $.ajax({
                                            url: data.lentURL,
                                            type: "POST",
                                            data: {note: $("#lendingNote"+ data.id).val() },
                                            success: function(data) {
                                                console.log(data);
                                                if(data.error === false) {
                                                    window.location.href = data.url;
                                                } else {
                                                    $.error(data.error);
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    }
                },
                gone: {
                    text: '<span class="icon mif-user-check"></span>',
                    btnClass: 'button dark float-left ' + (data.gone === true ? 'd-none' : ''),
                    action: function() {
                        window.location.href = data.goneURL;
                    }
                },
                close: {
                    text: '<span class="icon mif-cross"></span> <span class="d-none d-inline-md">Schließen</span>',
                    btnClass: 'button secondary',
                    keys: ["esc", "enter"]
                }
            }
        });
    }

    QrScanner.hasCamera().then(function(hasCamera) {
        if(!hasCamera) {
            $('[data-action="warehouseScanQR"]').addClass("disabled").attr("disabled", "disabled");
        }
    });

    function scanQRCode(action) {
        QrScanner.hasCamera().then(function(hasCamera) {
            let html;
            if(hasCamera) {
                let video = document.createElement('video');
                video.setAttribute('id', 'QRCodeScanner');

                html = video;
            } else {
                html = "<b>Es wurde keine kompatible Kamera gefunden.";
            }

            $alert = $.alert({
                title: "QR-Code scannen",
                content: html,
                onOpen: function() {
                    let qrScanner = new QrScanner(document.getElementById("QRCodeScanner"), function(result) {
                        $alert.close();
                        action(result);

                        qrScanner.stop();
                    });
                    qrScanner.start();
                },
                buttons: {
                    cancel: {
                        text: 'Abbrechen',
                        btnClass: 'button primary'
                    }
                }
            });
        });
    }

    let $alert;

    $(document).on("click", '.addWarehouseReadQR', function(e) {
        e.preventDefault();

        scanQRCode(function(result) {
            $("#warehouse_item_code").val(result);
        })
    });

    $(document).on("click", '[data-action="deleteWarehouseItemFinally"]', function(e) {
        e.preventDefault();

        let $elem = $(this);
        let id = $elem.data("id");
        let code = $elem.data("code");

        $.alert({
            title: "Bestätigung erforderlich",
            type: "orange",
            content: "Wollen Sie diesen Artikel wirklich <b>unwiederuflich</b> löschen?<br /><b>Diese Aktion kann nich rückgängig gemacht werden!</b>",
            buttons: {
                no: {
                    text: "Nein",
                    btnClass: "float-left button primary"
                },
                yes: {
                    text: "Ja",
                    btnClass: "button alert",
                    action: function() {
                        $.ajax({
                            url: "/warehouse/items/delete/"+ id +"/finally",
                            type: "POST",
                            data: {code: code},
                            success: function(data) {
                                if(data.error === false) {
                                    $.alert({
                                        title: "Artikel gelöscht",
                                        content: "Der Artikel wurde erfolgreich gelöscht.",
                                        buttons: {
                                            ok: {
                                                text: "Ok",
                                                btnClass: "button primary",
                                                action: function() {
                                                    window.location.reload();
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    $.alert({
                                        title: "Fehler",
                                        type: "red",
                                        content: data.error
                                    });
                                }
                            }
                        });
                    }
                }
            }
        });
    });
};