import $ from "jquery";

export default function() {
    let items = 0;
    let start = $("#invoiceItems").data("start");

    if(start > 0) {
        items = start;
    }

    if($("#addInvoice").length) {
        $(document).on("click", "#invoiceAddItem", function(e) {
            e.preventDefault();
            items++;

            let html = $("#invoiceAddItemTemplate").html();
            html = html.replace(/REPLACEID/g, items);
            html = '<div id="itm-'+ items + '">'+ html + '</div>';

            $("#invoiceItems").append(html);
        });

        $(document).on("click", '[data-action="deleteInvoiceItem"]', function(e) {
            e.preventDefault();

            let id = $(this).data("id");
            let $elem = $("#itm-"+ id);

            let deletedItems = $("#deletedItems");
            if(deletedItems.length) {
                let item = $elem.data("item");
                let val = deletedItems.val().length > 0 ? deletedItems.val().split(",") : [];
                val.push(item);

                deletedItems.val(val.join(","));
            }

            $elem.remove();
        });
    }

    $(document).on("click", '[data-action="changeInvoiceStatus"]', function(e) {
        e.preventDefault();

        let id = $(this).data("id");
        let status = $(this).data("status");

        $.confirm({
            title: 'Rechnungsstatus ändern:',
            content: 'Wollen Sie den Rechnungsstatus wirklich ändern?',
            buttons: {
                cancel: {
                    text: 'Abbrechen',
                    btnClass: 'button secondary'
                },
                confirm: {
                    text: 'Speichern',
                    btnClass: 'button primary',

                    action: function () {
                        $.redirectPost(window.location.href, {id: id, status: status});
                    }
                }
            }
        });

    });
};