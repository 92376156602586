/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.less');

import $ from 'jquery';
import "metro4";
import "jquery-confirm";
import "chart.js";
import 'select2';
import basictable from './basictable';
import autocomplete from './jquery.autocomplete';
import initNavbar from './navbar';
import invoice from './invoice';
import offer from './offer';
import dashboard from './dashboard';
import warehouse from './warehouse';
import tippy from 'tippy.js';

basictable();
autocomplete();

$.extend({
    redirectPost: function(location, args) {
        let form = $('<form></form>');
        form.attr("method", "post");
        form.attr("action", location);

        $.each( args, function(key, value) {
            let field = $('<input></input>');

            field.attr("type", "hidden");
            field.attr("name", key);
            field.attr("value", value);

            form.append(field);
        });
        $(form).appendTo('body').submit();
    }
});

$.error = function (msg) {
    return $.alert({
        title: "Fehler",
        type: "red",
        content: msg,
        buttons: {
            ok: {
                text: "Ok",
                btnClass: "button primary"
            }
        }
    });
};

jconfirm.defaults = {
    theme: 'material',
    type: 'green',
    draggable: false,
    columnClass: 'cell-md-4 offset-md-4 cell-sm-6 offset-sm-3 cell-xs-10 offset-xs-1',
};

tippy('.tippy');

initNavbar();
invoice();
offer();
dashboard();
warehouse();

$('.select2').select2();

$(document).on("click", "a[data-confirm]", function(e) {
    e.preventDefault();
    let href = $(this).attr("href");
    let title = $(this).data("confirm");

    $.confirm({
        title: 'Bestätigung erforderlich',
        content: title,
        buttons: {
            cancel: {
                text: 'Abbrechen',
                btnClass: 'button secondary'
            },
            confirm: {
                text: 'Bestätigen',
                btnClass: 'button primary',

                action: function () {
                    window.location.href = href;
                }
            }
        }
    });
});

if($(document).find(".changelog").length) {
    let $elem = $(".changelog");
    let msg = $elem.html();

    $.alert({
        title: 'Update erfolgreich eingespielt',
        content: msg,
        buttons: {
            ok: {
                text: 'Ok',
                btnClass: 'button primary'
            }
        }
    });
}

$('table.basictable').basictable();

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js!!');
