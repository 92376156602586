import $ from "jquery";
import "jquery-touchswipe";

export default function() {
    $(document).on("click", "#navbarToggle, #overlay", function(e) {
        e.preventDefault();
        let $overlay = $("#overlay");
        $(".notification_center").removeClass("in");

        if($overlay.hasClass("in")) {
            $("#navbar").removeClass("sidenav-simple-expand-fs");
            $overlay.fadeOut().removeClass("in");
        } else {
            $("#navbar").addClass("sidenav-simple-expand-fs");
            $overlay.fadeIn().addClass("in");
        }
    });
};